import { defineStore } from 'pinia';
import lodashSortBy from 'lodash-es/sortBy';
import { useSearch } from '@amplience/composables/useSearch';
import { ArticleListFiltersInterface, ArticleSelectedTagsInterface } from '@amplience/types';
import { SearchParamsInterface } from '@amplience/composables/useSearch/useSearch';
import { setQueryParams } from '@amplience/helpers/filterUrlResolver';

export const useFilters = defineStore('filters', {
  state: () =>
    <ArticleListFiltersInterface>{
      articles: [],
      cachedArticles: {},
      categories: [],
      selectedCategory: '',
      tags: [],
      selectedTags: [],
      selectedSortBy: '',
      currentPage: 1,
      pageSize: 24,
      totalItems: 0,
      totalPages: 0,
      isLoaded: false,
    },
  getters: {
    queryParams(state: ArticleListFiltersInterface): any {
      const { selectedSortBy, selectedTags, pageSize, selectedCategory, currentPage } = state;
      const query = {} as any;

      if (selectedTags.length || selectedSortBy || selectedCategory === 'All') {
        query.pageSize = pageSize;
        query.page = currentPage;
      }

      if (selectedSortBy) {
        query.sortBy = selectedSortBy;
      }

      if (selectedTags.length) {
        query.tags = lodashSortBy(selectedTags.map((tag: ArticleSelectedTagsInterface) => tag.name)).join(',');
      }

      return query;
    },
    cacheId(state: ArticleListFiltersInterface): string {
      const { selectedSortBy, pageSize, selectedTags: selectedTagsObj, selectedCategory, currentPage } = state;
      const selectedTags = lodashSortBy(selectedTagsObj.map((tag: ArticleSelectedTagsInterface) => tag.name)).join('-');

      return `${selectedSortBy}_${pageSize}_${selectedTags}_${selectedCategory || 'All'}_${currentPage}`.replace(
        /\s/g,
        ''
      );
    },
    selectedCategoryData(state: ArticleListFiltersInterface): any {
      return state.categories.find((category) => category.name === state.selectedCategory);
    },
    selectedCategoryDeliveryId(): string {
      const category = this.selectedCategoryData;

      return category ? category.deliveryId : '';
    },
    selectedCategoryDeliveryKey(): string {
      const category = this.selectedCategoryData;

      return category ? category.deliveryKey : 'all';
    },
    selectedTagsIdByName(state: ArticleListFiltersInterface): any {
      return (queryTags: string[]): any[] => {
        return state.tags.filter((tag) => {
          return queryTags.find((queryTag) => tag.name === queryTag);
        });
      };
    },
    selectedTagsIds(state: ArticleListFiltersInterface): string[] {
      return state.selectedTags.map((tag: any) => tag.deliveryId);
    },
    fetchParams(state: ArticleListFiltersInterface): SearchParamsInterface {
      const preparedFilters = [];

      if (this.selectedTagsIds.length) {
        preparedFilters.push({
          fieldName: 'articleTags.id',
          conditions: {
            in: this.selectedTagsIds,
          },
        } as any);
      }

      if (this.selectedCategoryDeliveryKey !== 'all') {
        preparedFilters.push({
          fieldName: 'articleCategory.id',
          conditions: {
            eq: this.selectedCategoryDeliveryId,
          },
        } as any);
      }

      const filters = preparedFilters.length ? preparedFilters : {};

      return {
        indexName: 'articleSlots',
        sort: state.selectedSortBy === '' ? 'desc' : state.selectedSortBy,
        // API follows 0-based pagination
        params: { filters, page: state.currentPage - 1, hitsPerPage: state.pageSize },
      };
    },
    itemsRange(state: ArticleListFiltersInterface): any {
      let end = state.currentPage * state.pageSize;
      const start = end - state.pageSize + 1;

      if (end > state.totalItems) end = state.totalItems;

      return { start, end };
    },
    searchedTags(state: ArticleListFiltersInterface): any {
      return (value: string) => {
        return state.tags.filter((tag) => tag.name.toLowerCase().startsWith(value.toLowerCase()));
      };
    },
    isFiltered(state: ArticleListFiltersInterface): any {
      return (query: any): boolean =>
        !!(
          query.sortBy ||
          query.tags ||
          query.pageSize ||
          query.page ||
          state.selectedSortBy ||
          state.selectedTags.length
        );
    },
  },
  actions: {
    async fetchCategories(ctx): Promise<void> {
      if (!this.categories.length) {
        const { items } = await this.fetchData(ctx, {
          indexName: 'categories',
        });
        this.categories = items;
      }
    },
    async fetchTags(ctx): Promise<void> {
      if (!this.tags.length) {
        const { items } = await this.fetchData(ctx, {
          indexName: 'tags',
          params: {
            hitsPerPage: 1000,
          },
        });
        this.tags = items;
      }
    },
    async fetchArticles(ctx): Promise<void> {
      this.isLoaded = false;
      setQueryParams(ctx.route.value, ctx.app.router, this.queryParams);

      if (this.cachedArticles[this.cacheId]) {
        this.pageSize = this.cachedArticles[this.cacheId].pageSize;
        this.articles = this.cachedArticles[this.cacheId].articles;
        this.totalPages = this.cachedArticles[this.cacheId].totalPages;
        this.currentPage = this.cachedArticles[this.cacheId].currentPage;
        this.totalItems = this.cachedArticles[this.cacheId].totalItems;
        this.selectedSortBy = this.cachedArticles[this.cacheId].selectedSortBy;
        this.selectedCategory = this.cachedArticles[this.cacheId].selectedCategory;
      } else {
        const { items, currentPage, count, countPages } = await this.fetchData(ctx, this.fetchParams);
        this.articles = items;
        this.totalItems = count;
        this.totalPages = countPages;
        this.currentPage = currentPage + 1; // API follows 0-based pagination
        this.cachedArticles[this.cacheId] = {
          pageSize: this.pageSize,
          articles: this.articles,
          totalPages: this.totalPages,
          currentPage: this.currentPage,
          selectedTags: this.selectedTags,
          totalItems: this.totalItems,
          selectedSortBy: this.selectedSortBy,
          selectedCategory: this.selectedCategory,
        };
      }

      this.isLoaded = true;
    },
    async fetchData(ctx, args?: SearchParamsInterface): Promise<any> {
      const { getData } = useSearch(ctx);

      return await getData({ ...args });
    },
    async setSelectedCategory(name: string, ctx): Promise<void> {
      this.selectedCategory = name;

      if (this.selectedCategory === 'All') {
        await this.fetchArticles(ctx);
      }
    },
    async setSelectedSortBy(ctx: any, sortBy: string): Promise<void> {
      this.selectedSortBy = sortBy;
      await this.fetchArticles(ctx);
    },
    async setPageSize(ctx, qty: number): Promise<void> {
      this.pageSize = qty;
      this.currentPage = 1;
      await this.fetchArticles(ctx);
    },
    async setCurrentPage(ctx, qty: number): Promise<void> {
      this.currentPage = qty;
      await this.fetchArticles(ctx);
    },
    async setFromQueryParams(ctx): Promise<void> {
      const query = ctx.query.value;

      if (query.sortBy) {
        this.selectedSortBy = query.sortBy;
      }

      if (query.tags) {
        await this.fetchTags(ctx);
        const tags = query.tags.split(',');
        this.selectedTags = this.selectedTagsIdByName(tags);
      }

      if (query.page) {
        this.currentPage = query.page;
      }

      if (query.pageSize) {
        this.pageSize = Number(query.pageSize);
      }

      if (query.sortBy || query.tags || query.pageSize || query.page) {
        await this.fetchArticles(ctx);
      }
    },
    async changeSelectedTags(ctx: any, tagId: string): Promise<void> {
      if (this.selectedTags.find((item: any) => item.deliveryId === tagId)) {
        this.selectedTags = this.selectedTags.filter((item: any) => item.deliveryId !== tagId) || [];
      } else {
        this.selectedTags.push(this.tags.find((item: any) => item.deliveryId === tagId));
      }

      if (this.currentPage !== 1) {
        await this.setCurrentPage(ctx, 1);
      }

      await this.fetchArticles(ctx);
    },
    async clearSelectedSortBy(ctx: any): Promise<void> {
      this.selectedSortBy = '';
      await this.fetchArticles(ctx);
    },
    async clearSelectedTags(ctx: any): Promise<void> {
      this.selectedTags = [];
      await this.fetchArticles(ctx);
    },
    resetFilters(): void {
      this.selectedSortBy = '';
      this.selectedTags = [];
      this.currentPage = 1;
      this.pageSize = 24;
      this.selectedCategory = '';
      this.totalItems = 0;
    },
  },
});
