




























import { defineComponent, useContext, useFetch, ref, PropType } from '@nuxtjs/composition-api';
import { PoiInterface, RenderPoiSource } from '@amplience/types';
import { useFilters } from '@amplience/stores/filters';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ArticleCard',
  props: {
    poi: {
      type: Object as PropType<PoiInterface>,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    categoryId: {
      type: String,
      default: '',
    },
    sources: {
      type: Array as PropType<RenderPoiSource[]>,
      default: () => [],
    },
    articleLink: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const filter = useFilters();
    const context = useContext();
    const { categories } = storeToRefs(filter);
    const categoryList = ref<any>([]);

    useFetch(async (): Promise<void> => {
      categoryList.value = await filter.fetchCategories(context);
    });

    const getCategoryName = () => {
      const category = categories.value.find((category) => category.deliveryId === props.categoryId);

      return category ? category.name : '';
    }

    return {
      getCategoryName,
      isPoi: (poi: PoiInterface): boolean => !!poi?.image,
    };
  },
})
